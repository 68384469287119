import utils from "./utils";

function setCookie(name, value, daysToLive) {
  // Encode value in order to escape semicolons, commas, and whitespace
  var cookie = name + "=" + encodeURIComponent(value);

  if (typeof daysToLive === "number") {
    /* Sets the max-age attribute so that the cookie expires
      after the specified number of days */
    cookie += "; max-age=" + daysToLive * 24 * 60 * 60;

    document.cookie = cookie;
  }
}

function getCookie(name) {
  // Split cookie string and get all individual name=value pairs in an array
  var cookieArr = document.cookie.split(";");

  // Loop through the array elements
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");

    /* Removing whitespace at the beginning of the cookie name
      and compare it with the given string */
    if (name == cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }

  // Return null if not found
  return null;
}

export default () => {
  document.querySelectorAll("section.pledge,section.pledge-2").forEach((el) => {
    const els = utils.getElements(
      {
        counter: ".counter",
        button: ".std-btn",
        textbox: ".take-pledge-section",
        newtext: ".new-text",
      },
      el
    );
    // see if there has been a cookie set already for the pledge and if so, hide the
    // pledge button and don't bother wiring it up
    if (getCookie("_pta_") == "true") {
      // already took it, don't let them do it again.
      document.querySelector(".take-pledge-section").classList.add("taken");
    } else {
      if (!els.$nulls) {
        // all of the css transitioning when the button is clicked performed here
        const transitionTextMessage = () => {
          els.newtext.style.display = "flex";
          requestAnimationFrame(() => {
            const height = els.textbox.getBoundingClientRect().height;
            const newHeight = els.newtext.getBoundingClientRect().height;
            // Determine if we should do the height transition
            const doHeightTransition =
              newHeight > height ||
              (document.body.classList.contains("alt-layout") &&
                innerWidth <= 600);
            if (doHeightTransition) {
              els.textbox.style.height = height + "px";
            }
            els.newtext.style.position = "absolute";
            requestAnimationFrame(() => {
              if (doHeightTransition) {
                els.textbox.style.height = newHeight + "px";
              }
              els.textbox.classList.add("transitioning");
              els.newtext.style.removeProperty("display");
              setTimeout(() => {
                els.textbox.style.removeProperty("height");
                els.newtext.style.removeProperty("position");
                els.textbox.classList.replace("transitioning", "taken");
              }, 300);
            });
          });
        };
        // increment the count
        const incrementNumber = () => {
          // going to do an optimistic increment here as wel as trigger the increment server-side
          let currCount = new Number(els.counter.dataset["count"]).valueOf();
          currCount++;
          els.counter.innerHTML = formatNumber(currCount);

          // going to optimistically let this run
          fetch("/api/take-the-pledge");
        };

        els.button.addEventListener("click", () => {
          if (!els.textbox.classList.contains("taken")) {
            // put in a cookie so they can't do it again for 30 days
            setCookie("_pta_", "true", 30);

            incrementNumber();
            // Now swap out the text message
            transitionTextMessage();
          }
        });
      }
    }
    // Cloudflare pages is aggressive in caching out html pages so frequently the page won't
    // have the correct count value in it. Because of that, we'll just make a little call
    // here to get the latest value and refresh the counter with the latest value
    fetch("/api/current-pledge-count")
      .then((response) => response.text())
      .then((count) => {
        els.counter.dataset["count"] = count;
        els.counter.innerHTML = formatNumber(new Number(count).valueOf());
      });
  });
};

function formatNumber(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
