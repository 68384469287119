type HTMLQuery<T> = T extends Array<string>
  ? Array<HTMLElement>
  : T extends { ignoreNull: true }
  ? HTMLElement | null
  : HTMLElement;
type HTMLQueryStrict<T> = T extends Array<string>
  ? Array<HTMLElement>
  : HTMLElement | null;
type HTMLQueryMap<O> = { [Property in keyof O]: HTMLQuery<O[Property]> };
type HTMLQueryMapStrict<O> = {
  [Property in keyof O]: HTMLQueryStrict<O[Property]>;
};
type HTMLQueryOptions = {
  selector: string;
  ignoreNull?: boolean;
};

export default {
  getRGB(color: string): Array<number> {
    var el = document.createElement("span");
    el.style.color = color;
    document.body.append(el);
    var color = getComputedStyle(el).color;
    el.remove();
    var match = color.match(/rgba?\((.+)\)/);
    if (match) {
      return match[1]
        .split(", ")
        .map((n, i) => (i !== 3 ? parseInt(n) : Math.round(Number(n) * 255)));
    }
    return [];
  },

  mixColors(...colors: Array<Array<number>>) {
    const defaults = [0, 0, 0, 255];
    const result: Array<number> = [];
    for (let j = 0; j < defaults.length; j++) {
      const value = defaults[j];
      for (let i = 0; i < colors.length; i++) {
        const color = colors[i];
        const channelValue = color[j] || value;
        result[j] += channelValue;
      }
    }
    for (let i = 0; i < result.length; i++) {
      const value = result[i];
      result[i] = value / colors.length;
    }
    return result;
  },

  mixColorPair(
    color1: Array<number>,
    color2: Array<number>,
    mixFactor: number
  ) {
    const maxsize = Math.max(color2.length, color1.length, 3);
    if (color1.length < 4) {
      color1 = Object.assign([], [0, 0, 0, 255], color1);
    }
    if (color2.length < 4) {
      color2 = Object.assign([], [0, 0, 0, 255], color2);
    }
    const mix = [
      color1[0] * (1 - mixFactor) + color2[0] * mixFactor,
      color1[1] * (1 - mixFactor) + color2[1] * mixFactor,
      color1[2] * (1 - mixFactor) + color2[2] * mixFactor,
      color1[3] * (1 - mixFactor) + color2[3] * mixFactor,
    ];
    mix.length = maxsize;
    return mix;
  },
  smoothScroll(
    to: number,
    callback: Function | null = null,
    speed = 8,
    container: typeof window | HTMLElement = window,
    vertical = true
  ) {
    var smoothScrollFeature =
      "scrollBehavior" in document.documentElement.style;
    var i = parseInt(
      container == window
        ? vertical
          ? container.pageYOffset
          : container.pageXOffset
        : vertical
        ? (container as any).scrollTop
        : (container as any).scrollLeft
    );
    if (i != to) {
      if (!smoothScrollFeature || callback) {
        to = parseInt(to as any);
        if (i < to) {
          var int = setInterval(function () {
            if (i > to - 20) i += 1;
            else if (i > to - 40) i += 3;
            else if (i > to - 80) i += 8;
            else if (i > to - 160) i += 18;
            else if (i > to - 200) i += 24;
            else if (i > to - 300) i += 40;
            else i += 60;
            vertical ? container.scroll(0, i) : container.scroll(i, 0);
            if (i >= to) {
              clearInterval(int);
              if (callback) {
                callback();
              }
            }
          }, speed);
        } else {
          var int = setInterval(function () {
            if (i < to + 20) i -= 1;
            else if (i < to + 40) i -= 3;
            else if (i < to + 80) i -= 8;
            else if (i < to + 160) i -= 18;
            else if (i < to + 200) i -= 24;
            else if (i < to + 300) i -= 40;
            else i -= 60;
            vertical ? container.scroll(0, i) : container.scroll(i, 0);
            if (i <= to) {
              clearInterval(int);
              if (callback) {
                callback();
              }
            }
          }, speed);
        }
      } else {
        container.scroll({
          top: vertical ? to : 0,
          left: vertical ? 0 : to,
          behavior: "smooth",
        });
      }
    } else if (callback) {
      callback();
    }
  },
  isTouchDevice4() {
    var prefixes = " -webkit- -moz- -o- -ms- ".split(" ");

    var mq = function (query: string) {
      return window.matchMedia(query).matches;
    };
    var DocumentTouch = (window as any).DocumentTouch;
    if (
      "ontouchstart" in window ||
      (DocumentTouch && document instanceof DocumentTouch)
    ) {
      return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    var query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join(
      ""
    );
    return mq(query);
  },
  copyToClipboard(str: string) {
    const el = document.createElement("textarea");
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  },
  getElements<
    T extends { [key: string]: string | string[] | HTMLQueryOptions }
  >(
    selectors: T,
    container: Document | Element = document
  ):
    | (HTMLQueryMapStrict<T> & { $nulls: Array<string> })
    | (HTMLQueryMap<T> & { $nulls: false }) {
    const elements: any = {
      $nulls: [],
    };
    for (const name in selectors as { [key: string]: any }) {
      if (Object.prototype.hasOwnProperty.call(selectors, name)) {
        const selector = selectors[name];
        if (selector instanceof Array) {
          elements[name] = Array.from(
            container.querySelectorAll(selector.join())
          );
        } else if (selector instanceof Object) {
          elements[name] = container.querySelector(selector.selector);
          if (!elements[name] && !selector.ignoreNull) {
            elements.$nulls.push(name);
          }
        } else {
          elements[name] = container.querySelector(selector);
          if (!elements[name]) {
            elements.$nulls.push(name);
          }
        }
      }
    }
    if (!elements.$nulls.length) {
      elements.$nulls = false;
    }
    return elements;
  },
};
