import utils from "./utils";

export default () => {
  document.querySelectorAll(".popup-carousel .carousel").forEach((el) => {
    const els = utils.getElements(
      {
        prevArrow: ".prev-btn",
        nextArrow: ".next-btn",
        items: [".carousel-item"],
        itemContainer: ".carousel-items",
      },
      el
    );

    if (!els.$nulls) {
      els.prevArrow.addEventListener("click", () => {
        // If we need to scroll at all
        if (els.itemContainer.scrollLeft > 0) {
          const itemWidth = els.items[0].getBoundingClientRect().width;
          // Calc the index based on the current scroll position and the offet to go to the previous
          const scrollIndex = Math.floor(
            (els.itemContainer.scrollLeft - itemWidth / 3) / itemWidth
          );
          // Make sure index is in bounds
          if (scrollIndex >= 0) {
            // Calc the scroll position and smooth scroll there
            const pos =
              els.items[scrollIndex].getBoundingClientRect().left +
              els.itemContainer.scrollLeft -
              els.itemContainer.getBoundingClientRect().left;
            utils.smoothScroll(pos, null, 8, els.itemContainer, false);
          }
        }
      });
      els.nextArrow.addEventListener("click", () => {
        const itemWidth = els.items[0].getBoundingClientRect().width;
        // Calc the index based on the current scroll position and the offet to go to the next
        const scrollIndex = Math.ceil(
          (els.itemContainer.scrollLeft + 1) / itemWidth
        );
        // Make sure index is in bounds
        if (scrollIndex < els.items.length) {
          // Calc the scroll position and smooth scroll there
          const pos =
            els.items[scrollIndex].getBoundingClientRect().left +
            els.itemContainer.scrollLeft -
            els.itemContainer.getBoundingClientRect().left;
          utils.smoothScroll(pos, null, 8, els.itemContainer, false);
        }
      });
    }
  });
};
