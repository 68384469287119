import "../css/main.scss";
import carousel from "./carousel";
import header from "./header";
import misc from "./misc";
import pledger from "./pledger";
import utils from "./utils";

document.addEventListener("DOMContentLoaded", (e) => {
  // Run code for the front end
  header();
  carousel();
  pledger();
  misc();
});

// Determine if the device is a touch device
(window as any).isTouch = utils.isTouchDevice4();
if ((window as any).isTouch) {
  document.querySelector("html")!.classList.add("is-touch");
}
