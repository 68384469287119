export default () => {
  const header = document.querySelector("header")!;
  if (header) {
    window.addEventListener("scroll", () => {
      if (scrollY >= 50 && !header.classList.contains("shrink")) {
        header.classList.add("shrink");
      } else if (scrollY < 50 && header.classList.contains("shrink")) {
        header.classList.remove("shrink");
      }
    });
  }
};
